<template>
  <div v-if="showImg" class="product-container" @click="goUrl">
    <img class="img1" :src="productInfo.landingUrl?productInfo.landingUrl:'https://file.kunqidata.com/page/1.png'"/>
    <img class="img2" src="https://file.kunqidata.com/page/2.png"/>
  </div>
  <div v-else class="product-container">
    <div class="swiper-box">
      <van-swipe class="swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="item in productInfo.picUrls" :key="item">
          <div class="swiper-item">
            <img :src="item" style="width:100%;height:100vw;" />
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>  
    <div class="product-info">
      <div class="price">¥{{productInfo.salesPrice}}</div> 
      <div class="product-tit">{{productInfo.name}}</div>
      <div class="product-des">{{productInfo.sellPoint}}</div>
    </div>
    
    <van-cell-group>
      <van-cell title-style="color: gray;" :title="'市场价:'+productInfo.marketPrice" :value="'销量：'+productInfo.saleNum" />
      <!-- <van-cell title-style="color: gray;" title="深圳发货" :value="'销量：'+productInfo.saleNum" /> -->
      <van-cell title-style="color: gray;" title="选择" :value="initialSku.name" @click="showSku" />
      <van-cell title-style="color: gray;" title="服务" value="假一赔十 | 七天无理由退款" />
    </van-cell-group>
     <div class="product-des-img" v-html="productInfo.description" />
     <van-goods-action>
      <!-- <van-goods-action-icon icon="chat-o" text="客服" />
      <van-goods-action-icon icon="shop-o" text="店铺" />
      <van-goods-action-button color="#2d2d2f " type="warning" text="加入购物车" /> -->
      <van-goods-action-button color="#ee0a24" type="danger" text="立即购买" @click="showSku" />
    </van-goods-action>
    <van-sku
      v-model="show"
      :sku="sku"
      :goods="goods"
      :goods-id="goodsId"
      :initial-sku="initialSku"
      :show-add-cart-btn="false"
      @buy-clicked="onBuyClicked"
      @add-cart="onAddCartClicked"
    />
  </div>
</template>

<script>
import axios from 'axios'
import {getQueryObject} from '../utils'
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      showImg: false,
      productInfo: {},
      value: 1,
      url: '',
      show: false,
      sku: {
        // 所有sku规格类目与其值的从属关系，比如商品有颜色和尺码两大类规格，颜色下面又有红色和蓝色两个规格值。
        // 可以理解为一个商品可以有多个规格类目，一个规格类目下可以有多个规格值。
        tree: [
          {
            k: '规格', // skuKeyName：规格类目名称
            k_s: 's1', // skuKeyStr：sku 组合列表（下方 list）中当前类目对应的 key 值，value 值会是从属于当前类目的一个规格值 id
            v: [
              {
                id: '1', // skuValueId：规格值 id
                name: '红色', // skuValueName：规格值名称
                imgUrl: 'https://img01.yzcdn.cn/1.jpg', // 规格类目图片，只有第一个规格类目可以定义图片
                previewImgUrl: 'https://img01.yzcdn.cn/1p.jpg', // 用于预览显示的规格类目图片
              },
              {
                id: '2',
                name: '蓝色',
                imgUrl: 'https://img01.yzcdn.cn/2.jpg',
                previewImgUrl: 'https://img01.yzcdn.cn/2p.jpg',
              }
            ],
            largeImageMode: true, //  是否展示大图模式
          }
        ],
        // 所有 sku 的组合列表，比如红色、M 码为一个 sku 组合，红色、S 码为另一个组合
        list: [
          {
            id: 2259, // skuId
            s1: '1', // 规格类目 k_s 为 s1 的对应规格值 id
            price: 100, // 价格（单位分）
            stock_num: 110 // 当前 sku 组合对应的库存
          },
          {
            id: 2359, // skuId
            s1: '2', // 规格类目 k_s 为 s1 的对应规格值 id
            price: 10, // 价格（单位分）
            stock_num: 10 // 当前 sku 组合对应的库存
          }
        ],
        price: '1.00', // 默认价格（单位元）
        stock_num: 227, // 商品总库存
        collection_id: 2261, // 无规格商品 skuId 取 collection_id，否则取所选 sku 组合对应的 id
        none_sku: false, // 是否无规格商品
        hide_stock: false // 是否隐藏剩余库存
      },
      goods: {
        // 默认商品 sku 缩略图
        picture: 'https://img01.yzcdn.cn/1.jpg'
      },
      goodsId: '',
      initialSku: {},
      goodsSkus: [],
      aIndex: 1
    }
    
  },
  created() {
    this.getProductInfo()
    // this.getScheme()
  },
  methods: {
    getProductInfo() {
      const query = getQueryObject()
      if (!query.id) return
      axios({
        method: 'get',
        url: `https://hzm.kunqidata.com/weixin/api/ma/goodsspu/findById`,
        params: {
          id: query.id,
          query: window.location.search&&window.location.search.split('?')[1]
        }
      }).then(res => {
        this.productInfo = res.data.data
        document.title = this.productInfo.name
        this.showImg = this.productInfo.isVerify === 1
        this.goodsSkus = this.productInfo.goodsSkuList
        this.goodsId = query.id
        this.sku = {
          tree: [{
            k: '规格', // skuKeyName：规格类目名称
            k_s: 's1', // skuKeyStr：sku 组合列表（下方 list）中当前类目对应的 key 值，value 值会是从属于当前类目的一个规格值 id
            v: this.productInfo.goodsSkuList.map(item => {
              return {
                id: item.id, // skuValueId：规格值 id
                name: item.describes, // skuValueName：规格值名称
                imgUrl: item.url, // 规格类目图片，只有第一个规格类目可以定义图片
                previewImgUrl: item.url,
              }
            }),
            largeImageMode: false, //  是否展示大图模式
          }],
          list: this.productInfo.goodsSkuList.map(item => {
            return {
              id: item.id, // skuId
              s1: item.id, // 规格类目 k_s 为 s1 的对应规格值 id
              price: item.price*100, // 价格（单位分）
              stock_num: this.productInfo.stock // 当前 sku 组合对应的库存
            }
          }),
          price: this.productInfo.goodsSkuList[0].price,
          stock_num: this.productInfo.stock

        }
        this.initialSku = {
          s1: this.productInfo.goodsSkuList[0].id,
          name: this.productInfo.goodsSkuList[0].describes,
          // 初始选中数量
          selectedNum: 1,

        }
        this.goods = {
          picture: this.productInfo.goodsSkuList[0].url
        }
      })
    },
    getScheme(str) {
      console.log(str)
      const that = this
      const query = getQueryObject()
      axios({
        method: 'get',
        url: `https://hzm.kunqidata.com/weixin/api/ma/goodsspu/findToken`,
        params: {
          id: query.id,
          query: str?(window.location.search&&window.location.search.split('?')[1])+'&'+str:window.location.search&&window.location.search.split('?')[1]
        }
      }).then(a => {
        axios({
          method: 'get',
          url: `https://hzm.kunqidata.com/weixin/api/ma/goodsspu/scheme2`,
          params: {
            query: str?(window.location.search&&window.location.search.split('?')[1])+'&'+str:window.location.search&&window.location.search.split('?')[1],
            token: a.data.msg
          }
        }).then(res => {
          this.url = res.data.data
         if (this.url) {
          window.location.href = this.url
         } else {
          if (that.aIndex <= 3) {
            that.getScheme(str)
            that.aIndex = that.aIndex + 1
          }
          
         }
         
            
          
          
        })
      })
      
    },
    // getScheme(str) {
    //   const query = getQueryObject()
    //   axios({
    //     method: 'get',
    //     url: `https://hzm.kunqidata.com/weixin/api/ma/goodsspu/scheme`,
    //     params: {
    //       id: query.id,
    //       query: str?(window.location.search&&window.location.search.split('?')[1])+'&'+str:window.location.search&&window.location.search.split('?')[1]
    //     }
    //   }).then(res => {
    //     this.url = res.data.data
    //       if (str) {
    //         window.location.href = this.url
    //       }
    //   })
      
    // },
    showSku() {
      this.show = true
    },
    goUrl() {
      // window.location.href = this.url
      this.getScheme()
    },
    onBuyClicked(e) {
      
      const idx = this.goodsSkus.findIndex(item => item.id === e.selectedSkuComb.id)
      this.getScheme(`skuIndex=${idx}&productNum=${e.selectedNum}`)
    },
    onAddCartClicked() {}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.scorllView{
  height: 100vh;
}
.swiper-box{
  width: 100%;
  height: 100vw;
  position: relative;
  border-bottom: 1px solid #ccc;
}
.swipe{
  height: 100vw;
  width: 100%;
}
.swiper-item{
  height: 100vw;
  width: 100%;
}
.swiper-item img{
  height: 100vw;
}
.product-info{
  padding: 0 16px;
  margin-bottom: 20px;
}
.price{
  color: #ee0a24;
  font-weight: bold;
  font-size: 24px;
  margin: 12px 0;
}
.product-tit{
  font-weight: bold;
}
.product-des{
  font-size: 12px;
  color: gray;
  margin-top: 8px;
}
.product-des-img p img{
  width: 100%;
  display: block;
}
.img1{
  width: 100%;
}
.img2{
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}
</style>
